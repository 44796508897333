<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on" @click="start()">
                <v-icon v-if="!sessao.id" large class="mr-2" color="grey">mdi-plus-circle-outline</v-icon>
                <v-icon v-else medium class="mr-2" color="grey">mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card class="ma-auto">
            <v-row>
                <v-col class="text-end">
                    <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
            <!-- <pre>
    {{ eqmd }} 
            </pre> -->
            <!-- <pre>{{ currentItem }}</pre> -->
            <MyCardTitle modulo="Cadastro ou Edição de Guia SADT" :card-info="sessao" />
            <v-col :cols="16" class="text-end">
                <v-btn icon @click="post_update()"><v-icon size="40">mdi-content-save</v-icon></v-btn>
                <v-btn v-show="sessao.id" icon @click="cancelar()"><v-icon size="40">mdi-cancel</v-icon></v-btn>

            </v-col>
            <v-card-text>

                <v-form ref="form_sessoes">
                    <v-row dense>

                        <v-col :cols="4">
                            <v-autocomplete outlined label="Operadora" v-model="sessao.operadora" return-object
                                :items="operadoras" item-value="id" item-text="nome" required
                                :rules="[(e) => e !== undefined || 'Obrigatório']"
                                @change="get_childrens_operadoras()"></v-autocomplete>
                        </v-col>
                        <v-col :cols="4">
                            <v-autocomplete outlined label="Paciente" v-model="sessao.paciente" return-object
                                :items="pacientes" item-value="id" item-text="nome" required
                                :rules="[(e) => e !== undefined || 'Obrigatório']"></v-autocomplete>
                        </v-col>
                        <v-col :cols="4">
                            <v-autocomplete outlined label="Procedimento" v-model="sessao.procedimento" return-object
                                :items="sessao.procedimento" item-value="id" item-text="nome" required
                                :rules="[(e) => e !== undefined || 'Obrigatório']"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col :cols="4">
                            <v-autocomplete v-model="sessao.equipe" outlined label="Equipe Multidisciplinar" multiple
                                :items="eqmd" item-value="id" item-text="username" required :rules="[
                                    (e) => (e !== undefined && e.length > 0) || 'Obrigatório',
                                ]"></v-autocomplete>
                        </v-col>
                        <v-col :cols="4">
                            <v-text-field outlined label="Data de início" v-model="sessao.data_inicio" type="date"
                                required :rules="[(e) => (e || '').length > 0 || 'Obrigatório']">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="4">
                            <v-text-field outlined label="Data de término" v-model="sessao.data_fim" type="date"
                                required :rules="[(e) => (e || '').length > 0 || 'Obrigatório']"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col :cols="4">
                            <v-autocomplete outlined label="Frequência" v-model="sessao.frequencia" return-object
                                :items="frequencias" item-value="id" item-text="nome" required
                                :rules="[(e) => e !== undefined || 'Obrigatório']"></v-autocomplete>
                        </v-col>
                        <v-col :cols="4">
                            <v-text-field outlined label="Quantidade" v-model="sessao.qtd" required
                                :rules="[(e) => (e || 0) > 0 || 'Obrigatório']"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col :cols="4">
                            <v-text-field outlined label="Senha" v-model="sessao.senha" required></v-text-field>
                        </v-col>
                        <v-col :cols="4">
                            <v-text-field outlined label="Data Início Senha" v-model="sessao.senha_data_inicio"
                                type="date" required></v-text-field>
                        </v-col>
                        <v-col :cols="4">
                            <v-text-field outlined label="Data Vigênia Senha" v-model="sessao.senha_data_validade"
                                type="date" required></v-text-field>
                        </v-col>

                    </v-row>

                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import api from "@/http"
export default {
    name: "Add",
    components: {
        MyCardTitle: () => import("../../uteis/card_title.vue"),
    },
    props: {
        currentItem: {
            type: Object,
        },
    },
    data: () => ({
        dialog: false,
        loading_em: false, // tipo boolean, para criar o preloader nos campos de equipe
        loading_pac: false, // tipo boolean, para criar o preloader nos campos de pacientes
        operadoras: [], // tipo array
        procedimentos: [],
        equipe_multidisciplinar: [], // array pra receber equipe multiciplinar
        pacientes: [], // array pra receber pacientes
        frequencias: [], //array para receber frequencias


    }),
    methods: {
        get_operadoras() {
            this.loading = true;
            api("operadoras/operadoras/")
                .then((response) => {
                    this.operadoras = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatr sessões");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        get_procedimentos() {
            this.loading = true;
            api("operadoras/procedimentos/")
                .then((response) => {
                    this.procedimentos = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatr sessões");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        get_procedimentos_operadora() {
            this.loading = true;
            api(
                `operadoras/procedimentos-operadora/?operadora=${this.sessao.operadora.id}`
            )
                .then((response) => {
                    this.procedimentos = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatr sessões");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        get_frequencias() {
            this.loading = true;
            api("atendimentos/frequencias/")
                .then((response) => {
                    this.frequencias = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitar frequencia");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        get_equipe() {
            this.loading_em = true;
            api("contas/equipe-multidisciplinar/")
                .then((response) => {
                    this.equipe_multidisciplinar = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatr equipe");
                })
                .finally(() => {
                    this.loading_em = false;
                });
        },
        get_pacientes() {
            this.loading_pac = true;
            if (this.sessao.operadora !== undefined) {
                api(`pacientes/listar/?operadora=${this.currentItem.operadora.id}`)
                    .then((response) => {
                        this.pacientes = response.data;
                    })
                    .catch(() => {
                        alert("Erro no link ao solicitar equipe");
                    })
                    .finally(() => {
                        this.loading_pac = false;
                    });
            }
        },
    

        cancelar() {
            this.sessao = {};
            this.dialog = false
        },
        get_childrens_operadoras() {
            this.get_pacientes();
            this.get_procedimentos_operadora();
        },
        post_update() {
            //console.log(this.$refs.form_sessoes.validate());
            if (!this.sessao.id) {
                api.post("atendimentos/sessoes/", this.currentItem).then(() => {
                    this.$emit('atualiza')
                    this.dialog = false
                });
            } else {
                api
                    .put(`atendimentos/sessoes/${this.sessao.id}/`, this.sessao)
                    .then(() => {
                        this.$emit('atualiza')
                        this.dialog = false
                    });
            }
        },
        start() {
            if (this.sessao.id !== undefined) {
                this.sessao.operadora = this.sessao.paciente.operadora
                this.get_childrens_operadoras();
            }
            this.get_equipe();
            this.get_frequencias();
            this.get_operadoras();
        },
    },
    computed: {
        eqmd() {
    
            return this.currentItem.equipe.filter((e) => e.especialidade === this.sessao.procedimento.id)
        },
        sessao: {
            get() {
                return this.currentItem
            },
            set() {
                return null
            }

        },
    }

};
</script>